import { useSettingsStore } from '@st/core/stores/useSettingsStore'
import { useCopyWithToast } from '@st/ui/composables'
import { useBonusesDeps } from '../../useDeps'

export function useReferralBonus() {
  const { isAuthenticated, referralCode, getReferralCurrency } =
    useBonusesDeps()
  const localReferralCode = computed(() => referralCode.value ?? '')
  const { settings } = storeToRefs(useSettingsStore())
  const readMoreUrl = usePublicFileUrl('publicFileReferralProgramTerms')
  const stFetch = useRawStFetch()
  const { t } = useI18n()

  const referralProgramData = ref({
    referralNumber: 0,
    referralAccrualNumber: 0,
    totalReferralAccrualAmount: '',
  })

  async function fetchReferralProgramData() {
    const { data } = await stFetch('/referral-program/info/get', {
      method: 'post',
    })
    if (data) {
      referralProgramData.value = data
    }
  }

  watchEffect(() => {
    if (isAuthenticated.value) {
      fetchReferralProgramData()
    }
  })

  const referralProgramCurrencyId = computed(
    () => settings.value?.referralProgramCurrencyId,
  )
  const currencySettings = computed(() => settings.value!.currencySettings)
  const referralCurrencySettings = computed(() => {
    if (!referralProgramCurrencyId.value) return undefined

    return currencySettings.value[referralProgramCurrencyId.value]
      .referralProgram
  })

  const rewardAmount = computed(() => {
    if (!referralCurrencySettings.value) return undefined

    return referralCurrencySettings.value.referrerRewardAmount
  })
  const thresholdAmount = computed(() => {
    if (!referralCurrencySettings.value) return undefined

    return referralCurrencySettings.value.totalBetThresholdAmount
  })
  const referralLink = computed(() => {
    const referalHostname = 'https://bet1.promo/?ref='

    return `${referalHostname}${localReferralCode.value}`
  })

  const referralCount = computed(
    () => referralProgramData.value?.referralNumber,
  )
  const referralRewardCount = computed(
    () => referralProgramData.value?.referralAccrualNumber,
  )

  const referralGottenAmount = computed(
    () => referralProgramData.value?.totalReferralAccrualAmount,
  )
  const referralCurrency = computed(() => {
    if (!referralProgramCurrencyId.value) return undefined

    return getReferralCurrency(referralProgramCurrencyId.value)
  })

  const { format: formatCrypto } = useCryptoFormatter({
    currency: computed(() => referralCurrency.value?.code ?? 'USDT'),
  })

  const formattedReward = computed(() => formatCrypto(rewardAmount.value ?? 0))
  const formattedTrashHold = computed(() =>
    formatCrypto(thresholdAmount.value ?? 0),
  )

  const { copy: copyLink, isCopied: isLinkCopied } = useCopyWithToast(
    referralLink,
    t('bonuses.copyLinkSuccess'),
  )
  const { copy: copyCode, isCopied: isCodeCopied } = useCopyWithToast(
    localReferralCode,
    t('bonuses.copyLinkSuccess'),
  )

  return {
    rewardAmount,
    thresholdAmount,
    referralCode,
    referralCount,
    referralRewardCount,
    referralGottenAmount,
    referralCurrency,
    referralLink,
    readMoreUrl,
    formattedTrashHold,
    formattedReward,
    isLinkCopied,
    copyLink,
    isCodeCopied,
    copyCode,
  }
}
